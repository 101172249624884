import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
//import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { OrderFormComponent } from './order-form/order-form.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { AcademicOrderService } from "./services/academic-order.service";
import { OrderPreviewComponent } from "./order-preview/order-preview.component";
import { FooterNavComponent } from './footer-nav/footer-nav.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsOpacity: 0.5,
  bgsPosition: "center-center",
  bgsSize: 30,
  bgsType: "square-loader",
  blur: 5,
  delay: 0,  
  fgsColor: "red",
  fgsPosition: "center-center",
  fgsSize: 80,
  fgsType: "double-bounce",
  gap: 24, 
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: true,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    OrderFormComponent,
    OrderSummaryComponent,
    OrderPreviewComponent,
    FooterNavComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    RouterModule.forRoot([
      { path: 'order', component: HomeComponent, pathMatch: 'full' },
      { path: 'order/:orderid/:email', component: HomeComponent },
      { path: 'order-preview/:orderid/:email', component: OrderPreviewComponent },

    ])
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LedxP4UAAAAAM5BEFsiMjp0P6zPERo2om5W3Sj4',
    } as RecaptchaSettings,
  }, AcademicOrderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
