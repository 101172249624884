import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AcademicServicesService } from '../academic-services.service';
import { AcademicOrderService } from '../services/academic-order.service';
import { Order } from '../services/order';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Timezone } from '../_models/TimeZone';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent implements OnInit, OnDestroy {
  public FormOrder: FormGroup;
  constructor(private formBuilder: FormBuilder,
    public dataService: AcademicServicesService,
    public orderservice: AcademicOrderService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute) { }

  encryptedEmail: string;
  encryptedOrderId: string;
  services;
  refence;
  serivcename;
  levelofwork;
  deadline;
  noofpages;
  pages;
  levelofworkname;
  WorkDelivery;
  packPrice;
  submitted = false;
  refrencing;
  objtemporder: Order;
  newOrder: Order;
  fileBase64: string;
  files: string[] = [];
  discountcode: string;
  refStyle: string;
  discountPercent: string;
  discountedPrice;
  totalprice;
  uploadfiles: string[] = [];
  IslenghtError = false;
  loading = false;
  btnMsg = "Preview My Order";
  tzvalue: string;
  tzError: string;
  timeZone: Timezone;
  siteCurrency: string;
  dailingCode: string;
  isDiscountAvailable: boolean;
  defaulDiscount: number;

  get f() { return this.FormOrder.controls; }

  getPrice() {
    const itemPrice = this.dataService.getPrice(this.serivcename, this.levelofworkname, this.WorkDelivery);

    if (itemPrice !== undefined && itemPrice.length > 0) {
      this.packPrice = (itemPrice[0].price * this.pages).toFixed(2);
      if (this.discountPercent !== undefined) {
        this.discountedPrice = (parseInt(this.discountPercent) / 100) * this.packPrice;
        this.totalprice = (this.packPrice - this.discountedPrice).toFixed(2);
      }
      else {
        this.discountPercent = "0";
        this.totalprice = this.packPrice;
        this.discountedPrice = 0;
      }

    }


    if (environment.discount > 0) {
      this.discountPercent = environment.discount.toString();
      this.discountedPrice = ((environment.discount / 100) * this.packPrice).toFixed(2);
      this.totalprice = (this.packPrice - this.discountedPrice).toFixed(2);
    }
  }

  getTimeZone() {
    const tz = this.dataService.getCountryTimeZone(this.tzvalue);

    if (tz === undefined) {
      this.timeZone = tz;
      this.tzError = "Invalid Dialing Code";
    }
  }

  getDiscount() {
    //const discount = this.orderservice.GetDiscount(this.discountcode);
    //console.log(discount);


    this.orderservice.GetDiscount(this.discountcode).subscribe(success => {
      if (success) {
        if (this.orderservice.discount != undefined) {
          this.discountPercent = this.orderservice.discount.amount;
          this.discountedPrice = ((parseInt(this.discountPercent) / 100) * this.packPrice).toFixed(2);
          this.totalprice = (this.packPrice - this.discountedPrice).toFixed(2);
        }
        else {
          this.discountPercent = "0";
          this.totalprice = this.packPrice;
          this.discountedPrice = 0;
        }

      }

      if (environment.discount > 0) {
        this.discountedPrice = ((environment.discount / 100) * this.packPrice).toFixed(2);
        this.totalprice = (this.packPrice - this.discountedPrice).toFixed(2);
      }

    });

  }


  fileChangeEvent(fileInput) {
    //this.uploadfiles = [];
    //this.files = [];
    for (let i = 0; i < fileInput.target.files.length; i++) {
      const fileItem = fileInput.target.files[i];
      if (fileItem.size > 5000000) {
        this.IslenghtError = true;
        fileInput.srcElement.value = null;
        return;
      }
    }
    for (let i = 0; i < fileInput.target.files.length; i++) {
      const reader = new FileReader();
      this.IslenghtError = false;
      reader.onload = (e: any) => {
        const imgBase64Path = e.target.result;
        this.fileBase64 = imgBase64Path;
        this.files.push(this.fileBase64);
      };
      reader.readAsDataURL(fileInput.target.files[i]);
      this.uploadfiles.push(fileInput.target.files[i]);

    }

  }

  removeFile(index) {
    this.uploadfiles.splice(index, 1);
  }

  onSubmit() {
 
    this.submitted = true;
    this.ngxLoader.start();
    // stop here if form is invalid
    if (this.FormOrder.invalid) {
      this.ngxLoader.stop();
      return;
    }


    this.loading = true;
    this.btnMsg = "Please wait...";
    this.objtemporder = new Order();
    this.objtemporder.email = this.FormOrder.get('email').value;
    this.objtemporder.name = this.FormOrder.get('name').value;
    this.objtemporder.phoneNumber = this.FormOrder.get('phoneCode').value.concat('-').concat(this.FormOrder.get('phone').value);
    this.objtemporder.deadline = this.FormOrder.get('deliverytime').value;
    this.objtemporder.levelOfWork = this.FormOrder.get('standard').value;
    this.objtemporder.noofPages = this.pages;
    this.objtemporder.paperInstructions = this.FormOrder.get('details').value;
    this.objtemporder.price = this.packPrice;//this.FormOrder.get('productprice').value;
    this.objtemporder.refrenceSytle = this.FormOrder.get('refrencing').value;
    this.objtemporder.siteName = environment.site;
    this.objtemporder.totalPrice = this.totalprice;
    this.objtemporder.typeofWork = this.FormOrder.get('pservices').value;
    this.objtemporder.files = this.files;
    this.objtemporder.discountCode = "";
    this.objtemporder.discountPrice = this.discountedPrice;

    //if (this.defaulDiscount > 0) {
    //  this.objtemporder.discountPrice = this.defaulDiscount.toString();
    //}
    

    if (this.timeZone !== undefined) {
      this.objtemporder.timeZone = this.timeZone.timeZone.toString();
    }

    if (this.newOrder !== undefined) {
      this.objtemporder.orderId = this.newOrder.orderId;
    }


    //let promise = new Promise((resolve, reject) => {
    //this.orderservice.SaveOrder(this.objtemporder).toPromise()
    //  .then(res => {

    //    this.router.navigate(['/order-preview/' + res.orderId + '/' + res.email]);
    //    //console.log(res);
    //    //console.log(res.email);
    //    //console.log(res.orderId);
    //    resolve();
    //  //this.TakeHome();
    //  });
    //});
    // display form values on success



    this.orderservice.SaveOrderData(this.objtemporder).subscribe(res => {
      this.newOrder = res;
      this.ngxLoader.stop();
      this.router.navigate(['/order-preview/' + res.encryptedOrderId + '/' + res.encryptedEmail]);
      //console.log(res);
      //this.TakeHome();
    });
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.FormOrder.value, null, 4));
  }

  ngOnDestroy() {
    this.orderservice.neworder = this.newOrder;
  }

  FillLevelOfWork() {
    const data = this.dataService.getLevelofWork(this.serivcename);
    this.levelofwork = data.levelofwork;
    this.deadline = data.deadline;
    this.noofpages = data.noofpages;

    this.WorkDelivery = "0";
    this.pages = 0;
    this.levelofworkname = "0";
  }

  ngOnInit() {
    this.services = this.dataService.getProducts();
    this.refence = this.dataService.getRefrenceStyle();
    this.serivcename = "0";
    this.discountPercent = "0";
    this.refStyle = "0";
    this.WorkDelivery = "0";
    this.pages = 0;
    this.levelofworkname = "0";
    this.tzvalue = environment.dialingcode;
    this.siteCurrency = environment.currency;
    this.defaulDiscount = environment.discount;

    if (this.defaulDiscount > 0) {
      this.isDiscountAvailable = true;
    }
    else {
      this.isDiscountAvailable = false;
    }

    
    this.FormOrder = new FormGroup({
      recap: new FormControl(null, Validators.required)
    });

    
      this.FormOrder = this.formBuilder.group({
        name: ["", [Validators.required]],
        email: ["", [Validators.email, Validators.required]],
        phone: ["", [Validators.required]],
        pservices: ["0", [Validators.minLength(2)]],
        standard: ["0", [Validators.minLength(2)]],
        deliverytime: ["0", [Validators.minLength(2)]],
        productprice: ["0", [Validators.min(1)]],
        details: ["", [Validators.required]],
        recap: [null, [Validators.required]],
        phoneCode: ["", [Validators.required]],
        refrencing: ["0", [Validators.minLength(2)]],
        terms: [false, [Validators.requiredTrue]],
      });
   

    this.route.params
      .subscribe(
        (params: Params) => {
         
          this.encryptedOrderId = params['orderid'];
          this.encryptedEmail = params['email'];

          if (this.encryptedEmail !== undefined && this.encryptedOrderId !== undefined) {
            this.ngxLoader.start();
            this.orderservice.GetOrder(this.encryptedEmail, this.encryptedOrderId).subscribe(success => {
              if (success) {
                if (this.orderservice.neworder !== undefined) {
                  this.newOrder = this.orderservice.neworder;
                  this.serivcename = this.newOrder.typeofWork;


                  this.FillLevelOfWork();
                  //this.refStyle = this.newOrder.refrenceSytle;

                  if (this.newOrder !== undefined) {
                    this.pages = this.newOrder.noofPages;
                    const pno = this.newOrder.phoneNumber.split('-');

                      this.FormOrder = this.formBuilder.group({
                      name: [this.newOrder.name, [Validators.required]],
                      email: [this.newOrder.email, [Validators.email, Validators.required]],
                        phone: [pno[1], [Validators.required]],
                      pservices: [this.newOrder.typeofWork, [Validators.minLength(2)]],
                      standard: [this.newOrder.levelOfWork, [Validators.minLength(2)]],
                      deliverytime: [this.newOrder.deadline, [Validators.minLength(2)]],
                      productprice: [this.newOrder.price, [Validators.min(1)]],
                        details: [this.newOrder.paperInstructions, [Validators.required]],
                        phoneCode: [pno[0], [Validators.required]],
                      refrencing: [this.newOrder.refrenceSytle, [Validators.minLength(2)]],
                      recap: [null, [Validators.required]],
                      terms: [false, [Validators.requiredTrue]],

                    });
                    this.getPrice();
                    this.totalprice = this.newOrder.totalPrice;
                    this.packPrice = this.newOrder.price;
                    this.levelofworkname = this.newOrder.levelOfWork;
                    //this.WorkDelivery = this.newOrder.levelOfWork;
                    this.ngxLoader.stop();
                  }
                }
              }
            });
          }         
        }
      );
  }

}
