export class Order {
  orderId: number;
  name: string;
  email: string;
  phoneNumber: string;
  timeZone: string;
  refrenceSytle: string;
  typeofWork: string;
  levelOfWork: string;
  deadline: string;
  noofPages: number;
  paperInstructions: string;
  discountCode: string;
  price: string;
  discountPrice: string;
  totalPrice: string;
  siteName: string;
  files: string[];
  encryptedEmail: string;
  encryptedOrderId: string;
  
}  
