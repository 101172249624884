import { Injectable } from '@angular/core';
import { Timezone } from './_models/TimeZone';

@Injectable({
  providedIn: 'root'
})
export class AcademicServicesService {

  timezone: Timezone[] = [
    { countryCode: "+93", timeZone: 175 },
    { countryCode: "+355", timeZone: 115 },
    { countryCode: "+213", timeZone: 95 },
    { countryCode: "+1684", timeZone: 1 },
    { countryCode: "+376", timeZone: 125 },
    { countryCode: "+244", timeZone: 95 },
    { countryCode: "+1264", timeZone: 50 },
    { countryCode: "+1268", timeZone: 50 },
    { countryCode: "+54", timeZone: 70 },
    { countryCode: "+374", timeZone: 170 },
    { countryCode: "+297", timeZone: 50 },
    { countryCode: "+61", timeZone: 255 },
    { countryCode: "+43", timeZone: 110 },
    { countryCode: "+994", timeZone: 170 },
    { countryCode: "+1242", timeZone: 56 },
    { countryCode: "+973", timeZone: 145 },
    { countryCode: "+880", timeZone: 195 },
    { countryCode: "+1246", timeZone: 50 },
    { countryCode: "+375", timeZone: 130 },
    { countryCode: "+32", timeZone: 105 },
    { countryCode: "+501", timeZone: 20 },
    { countryCode: "+229", timeZone: 95 },
    { countryCode: "+1441", timeZone: 65 },
    { countryCode: "+975", timeZone: 195 },
    { countryCode: "+591", timeZone: 13 },
    { countryCode: "+387", timeZone: 100 },
    { countryCode: "+267", timeZone: 115 },
    { countryCode: "+55", timeZone: 65 },
    { countryCode: "+246", timeZone: 195 },
    { countryCode: "+673", timeZone: 210 },
    { countryCode: "+359", timeZone: 125 },
    { countryCode: "+226", timeZone: 85 },
    { countryCode: "+257", timeZone: 115 },
    { countryCode: "+855", timeZone: 205 },
    { countryCode: "+237", timeZone: 95 },
    { countryCode: "+1", timeZone: 50 },
    { countryCode: "+238", timeZone: 83 },
    { countryCode: "+345", timeZone: 35 },
    { countryCode: "+236", timeZone: 95 },
    { countryCode: "+235", timeZone: 95 },
    { countryCode: "+56", timeZone: 65 },
    { countryCode: "+86", timeZone: 210 },
    { countryCode: "+61", timeZone: 205 },
    { countryCode: "+269", timeZone: 145 },
    { countryCode: "+242", timeZone: 95 },
    { countryCode: "+682", timeZone: 2 },
    { countryCode: "+506", timeZone: 20 },
    { countryCode: "+385", timeZone: 115 },
    { countryCode: "+53", timeZone: 50 },
    { countryCode: "+357", timeZone: 145 },
    { countryCode: "+420", timeZone: 115 },
    { countryCode: "+45", timeZone: 115 },
    { countryCode: "+253", timeZone: 145 },
    { countryCode: "+1767", timeZone: 50 },
    { countryCode: "+1849", timeZone: 50 },
    { countryCode: "+593", timeZone: 35 },
    { countryCode: "+20", timeZone: 120 },
    { countryCode: "+503", timeZone: 20 },
    { countryCode: "+240", timeZone: 95 },
    { countryCode: "+291", timeZone: 145 },
    { countryCode: "+372", timeZone: 145 },
    { countryCode: "+251", timeZone: 145 },
    { countryCode: "+500", timeZone: 65 },
    { countryCode: "+298", timeZone: 95 },
    { countryCode: "+679", timeZone: 285 },
    { countryCode: "+358", timeZone: 145 },
    { countryCode: "+33", timeZone: 105},
    { countryCode: "+594", timeZone: 65},
    { countryCode: "+689", timeZone: 3 },
    { countryCode: "+241", timeZone: 95 },
    { countryCode: "+220", timeZone: 85},
    { countryCode: "+995", timeZone: 165 },
    { countryCode: "+49", timeZone: 120 },
    { countryCode: "+233", timeZone: 90},
    { countryCode: "+350", timeZone: 115},
    { countryCode: "+30", timeZone: 145 },
    { countryCode: "+299", timeZone: 75},
    { countryCode: "+1473", timeZone: 50 },
    { countryCode: "+590", timeZone: 50},
    { countryCode: "+1671", timeZone: 255 },
    { countryCode: "+502", timeZone: 20 },
    { countryCode: "+224", timeZone: 85},
    { countryCode: "+595", timeZone: 50},
    { countryCode: "+509", timeZone: 50},
    { countryCode: "+504", timeZone: 20 },
    { countryCode: "+852", timeZone: 210},
    { countryCode: "+36", timeZone: 115 },
    { countryCode: "+354", timeZone: 85},
    { countryCode: "+91", timeZone: 190},
    { countryCode: "+62", timeZone: 205},
    { countryCode: "+98", timeZone: 160},
    { countryCode: "+964", timeZone: 158 },
    { countryCode: "+353", timeZone: 95},
    { countryCode: "+44", timeZone: 95},
    { countryCode: "+972", timeZone: 135},
    { countryCode: "+39", timeZone: 130 },
    { countryCode: "+1876", timeZone: 35 },
    { countryCode: "+81", timeZone: 235},
    { countryCode: "+962", timeZone: 145 },
    { countryCode: "+77", timeZone: 185},
    { countryCode: "+254", timeZone: 155},
    { countryCode: "+686", timeZone: 285},
    { countryCode: "+82", timeZone: 230},
    { countryCode: "+965", timeZone: 150},
    { countryCode: "+996", timeZone: 195},
    { countryCode: "+856", timeZone: 205},
    { countryCode: "+371", timeZone: 145},
    { countryCode: "+961", timeZone: 130},
    { countryCode: "+266", timeZone: 130},
    { countryCode: "+231", timeZone: 85},
    { countryCode: "+218", timeZone: 130},
    { countryCode: "+423", timeZone: 130 },
    { countryCode: "+370", timeZone: 150},
    { countryCode: "+352", timeZone: 130},
    { countryCode: "+853", timeZone: 210 },
    { countryCode: "+389", timeZone: 135},
    { countryCode: "+261", timeZone: 145 },
    { countryCode: "+60", timeZone: 215},
    { countryCode: "+265", timeZone: 130},
    { countryCode: "+960", timeZone: 185 },
    { countryCode: "+223", timeZone: 85 },
    { countryCode: "+356", timeZone: 130 },
    { countryCode: "+692", timeZone: 290 },
    { countryCode: "+596", timeZone: 56},
    { countryCode: "+222", timeZone:  85},
    { countryCode: "+230", timeZone: 165},
    { countryCode: "+262", timeZone: 158},
    { countryCode: "+52", timeZone: 30},
    { countryCode: "+373", timeZone: 160 },
    { countryCode: "+377", timeZone: 135 },
    { countryCode: "+976", timeZone: 207},
    { countryCode: "+1664", timeZone: 55},
    { countryCode: "+212", timeZone: 90 },
    { countryCode: "+258", timeZone: 115 },
    { countryCode: "+95", timeZone: 203 },
    { countryCode: "+264", timeZone: 140 },
    { countryCode: "+674", timeZone: 285},
    { countryCode: "+977", timeZone: 193 },
    { countryCode: "+599", timeZone: 115},
    { countryCode: "+31", timeZone: 115},
    { countryCode: "+1869", timeZone: 55},
    { countryCode: "+687", timeZone: 280 },
    { countryCode: "+64", timeZone: 290 },
    { countryCode: "+505", timeZone: 33 },
    { countryCode: "+227", timeZone: 105},
    { countryCode: "+234", timeZone: 105},
    { countryCode: "+683", timeZone: 1 },
    { countryCode: "+672", timeZone: 280},
    { countryCode: "+47", timeZone: 135 },
    { countryCode: "+968", timeZone: 165},
    { countryCode: "+92", timeZone: 185 },
    { countryCode: "+680", timeZone: 240},
    { countryCode: "+970", timeZone: 140 },
    { countryCode: "+507", timeZone: 40},
    { countryCode: "+675", timeZone: 260 },
    { countryCode: "+595", timeZone: 56 },
    { countryCode: "+51", timeZone: 40},
    { countryCode: "+63", timeZone: 215},
    { countryCode: "+872", timeZone: 4 },
    { countryCode: "+48", timeZone: 100},
    { countryCode: "+351", timeZone: 100},
    { countryCode: "+1939", timeZone: 50},
    { countryCode: "+974", timeZone: 150},
    { countryCode: "+262", timeZone: 170},
    { countryCode: "+40", timeZone: 160},
    { countryCode: "+7", timeZone: 165 },
    { countryCode: "+250", timeZone: 125},
    { countryCode: "+590", timeZone: 56 },
    { countryCode: "+290", timeZone: 85 },
    { countryCode: "+1869", timeZone: 56},
    { countryCode: "+1758", timeZone: 56 },
    { countryCode: "+590", timeZone: 56},
    { countryCode: "+508", timeZone: 75},
    { countryCode: "+1784", timeZone: 56},
    { countryCode: "+685", timeZone: 300 },
    { countryCode: "+1684", timeZone: 1},
    { countryCode: "+378", timeZone: 130 },
    { countryCode: "+239", timeZone: 100},
    { countryCode: "+966", timeZone: 150},
    { countryCode: "+221", timeZone:  85},
    { countryCode: "+381", timeZone: 115},
    { countryCode: "+248", timeZone: 170},
    { countryCode: "+232", timeZone:  85},
    { countryCode: "+65", timeZone: 215},
    { countryCode: "+421", timeZone: 130 },
    { countryCode: "+386", timeZone: 130},
    { countryCode: "+677", timeZone: 280 },
    { countryCode: "+252", timeZone: 145 },
    { countryCode: "+27", timeZone: 140},
    { countryCode: "+34", timeZone: 105},
    { countryCode: "+94", timeZone: 190},
    { countryCode: "+249", timeZone: 120},
    { countryCode: "+597", timeZone: 60},
    { countryCode: "+268", timeZone: 115 },
    { countryCode: "+46", timeZone: 115},
    { countryCode: "+41", timeZone: 115},
    { countryCode: "+963", timeZone: 158},
    { countryCode: "+886", timeZone: 220 },
    { countryCode: "+992", timeZone: 185},
    { countryCode: "+255", timeZone: 150},
    { countryCode: "+66", timeZone: 205 },
    { countryCode: "+228", timeZone: 85 },
    { countryCode: "+690", timeZone: 300 },
    { countryCode: "+1868", timeZone: 55},
    { countryCode: "+216", timeZone: 130 },
    { countryCode: "+993", timeZone: 185},
    { countryCode: "+1649", timeZone: 55 },
    { countryCode: "+688", timeZone: 285},
    { countryCode: "+256", timeZone: 140},
    { countryCode: "+380", timeZone: 145 },
    { countryCode: "+971", timeZone: 165 },
    { countryCode: "+44", timeZone: 85},
    { countryCode: "+1", timeZone: 50},
    { countryCode: "+598", timeZone: 65 },
    { countryCode: "+598", timeZone: 180},
    { countryCode: "+678", timeZone: 280},
    { countryCode: "+58", timeZone: 50 },
    { countryCode: "+84", timeZone: 205},
    { countryCode: "+1284", timeZone: 205},
    { countryCode: "+1340", timeZone: 205  },
    { countryCode: "+681", timeZone: 205 },
    { countryCode: "+967", timeZone: 150},
    { countryCode: "+260", timeZone: 140 },
    { countryCode: "+263", timeZone: 140  },

  ];

  

  product = [
    { name: "Assignment"},
    { name: "Essay"},
    { name: "Dissertation/Thesis"},
    { name: "Proofreading" },
    { name: "Financial Analysis" },
    { name: "PowerPoint Presentation with Speech" },
    { name: "Research Paper" },   
    { name: "Case Study" },
    { name: "Book Report" },
    { name: "Book Review" },
    { name: "Movie Review" },
    { name: "Research Proposal" },
    { name: "Report" },
    { name: "Research Summary" },
    { name: "Formatting" },
    { name: "Lab Report" }
  ];

  refrenceSytel = [
    { name: "APA" },
    { name: "MLA" },
    { name: "Chicago" },
    { name: "Turabian" },
    { name: "Harvard" },
    { name: "Does Not Matter" }];

  levelofwork = [
    { name: "Under Graduate", option: "Graduate" },
    { name: "Masters", option: "Masters" },
    { name: "Doctorate", option: "Doctorate" }
  ];

  levelofworkWithoudDoctorate = [
    { name: "Under Graduate", option: "Graduate" },
    { name: "Masters", option: "Masters" }
  ];

  prices = [];

  deadlines = [];
  academicwork: any;

  constructor() { }

  public getProducts(): Array<{ name}> {
    return this.product;
  }

  public getRefrenceStyle(): Array<{ name }> {
    return this.refrenceSytel;
  }

  public getCountryTimeZone(timezone: string): Timezone {
    return this.timezone.find(x => x.countryCode === timezone);
  }

  public getLevelofWork(productname: string) {
    switch (productname) {
      case "Essay":
      case "Assignment":
      case "Research Paper":
      case "Report":
      case "Case Study":
      case "Book Report":
      case "Book Review":
      case "Movie Review":
      case "Research Proposal":
      case "Research Summary":
      case "Lab Report":      
        this.deadlines = [          
          { name: "7 Days", option: "7 Days" },
          //{ name: "5 Days", option: "5 Days" },
          { name: "4 Days", option: "4 Days" },
          { name: "3 Days", option: "3 Days" },
          { name: "48 Hours", option: "48 Hours" },
          { name: "24 Hours", option: "24 Hours" }
        ]
        this.academicwork = { levelofwork: this.levelofwork, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
        break;      
      case "Financial Analysis":
        this.deadlines = [
          { name: "7 Days", option: "7 Days" },
          //{ name: "5 Days", option: "5 Days" },
          { name: "4 Days", option: "4 Days" },
          { name: "3 Days", option: "3 Days" },
          { name: "48 Hours", option: "48 Hours" },
          { name: "24 Hours", option: "24 Hours" }
        ];
       
        this.academicwork = { levelofwork: this.levelofworkWithoudDoctorate, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
      case "Proofreading":
        this.deadlines = [
          { name: "7 Days", option: "7 Days" },          
          { name: "4 Days", option: "4 Days" },
          { name: "3 Days", option: "3 Days" },
          { name: "48 Hours", option: "48 Hours" },
          { name: "24 Hours", option: "24 Hours" }
        ];
      
        this.academicwork = { levelofwork: this.levelofworkWithoudDoctorate, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
      case "Dissertation/Thesis":
        this.deadlines = [
          { name: "1 Month", option: "1 Month" },
          { name: "15 Days", option: "15 Days" },
          { name: "7 Days", option: "7 Days" },
          { name: "4 Days", option: "4 Days" }
        ];
        this.academicwork = { levelofwork: this.levelofwork, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
      case "PowerPoint Presentation with Speech":
        this.deadlines = [
          { name: "14 Days", option: "14 Days" },
          { name: "6-7 Days", option: "6-7 Days" },
          { name: "5 Days", option: "5 Days" },
          { name: "4 Days", option: "4 Days" },
          { name: "3 Days", option: "3 Days" },
          { name: "48 Hours", option: "48 Hours" },
          { name: "24 Hours", option: "24 Hours" }
        ];
        this.academicwork = { levelofwork: undefined, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
        break;
      case "Formatting":
        this.deadlines = [
          { name: "14 Days", option: "14 Days" },
          { name: "6-7 Days", option: "6-7 Days" },
          { name: "5 Days", option: "5 Days" },
          { name: "4 Days", option: "4 Days" },
          { name: "3 Days", option: "3 Days" },
          { name: "48 Hours", option: "48 Hours" },
          { name: "24 Hours", option: "24 Hours" }
        ];
        this.academicwork = { levelofwork: this.levelofwork, deadline: this.deadlines, noofpages: 300 };
        return this.academicwork;
        break;

    }
  }

  public getPrice(productname: string, levelofwork: string, deadline: string) {
    switch (productname) {
      case "Essay":
      case "Assignment":
      case "Research Paper":
      case "Report":
      case "Case Study":
      case "Book Report":
      case "Book Review":
      case "Movie Review":
      case "Research Proposal":
      case "Research Summary":
      case "Lab Report":
        this.prices = [
          { deadline: "7 Days", levelofwork: "Graduate", price: 19.95 },
          { deadline: "4 Days", levelofwork: "Graduate", price: 21.95 },
          { deadline: "3 Days", levelofwork: "Graduate", price: 23.95 },
          { deadline: "48 Hours", levelofwork: "Graduate", price: 25.95 },
          { deadline: "24 Hours", levelofwork: "Graduate", price: 27.95 },
          { deadline: "7 Days", levelofwork: "Masters", price: 21.95 },
          { deadline: "4 Days", levelofwork: "Masters", price: 23.95 },
          { deadline: "3 Days", levelofwork: "Masters", price: 25.95 },
          { deadline: "48 Hours", levelofwork: "Masters", price: 27.95 },
          { deadline: "24 Hours", levelofwork: "Masters", price: 29.95 },
          { deadline: "7 Days", levelofwork: "Doctorate", price: 29.95 },
          { deadline: "4 Days", levelofwork: "Doctorate", price: 31.95 },
          { deadline: "3 Days", levelofwork: "Doctorate", price: 33.95 },
          { deadline: "48 Hours", levelofwork: "Doctorate", price: 35.95 },
          { deadline: "24 Hours", levelofwork: "Doctorate", price: 37.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline && item.levelofwork === levelofwork);
        break;
      case "Proofreading":
        this.prices = [
          { deadline: "7 Days", levelofwork: "Graduate", price: 7.95 },
          { deadline: "4 Days", levelofwork: "Graduate", price: 9.50 },
          { deadline: "3 Days", levelofwork: "Graduate", price: 10.95 },
          { deadline: "48 Hours", levelofwork: "Graduate", price: 12.50 },
          { deadline: "24 Hours", levelofwork: "Graduate", price: 13.95 },
          { deadline: "7 Days", levelofwork: "Masters", price: 7.95 },
          { deadline: "4 Days", levelofwork: "Masters", price: 9.50 },
          { deadline: "3 Days", levelofwork: "Masters", price: 10.95 },
          { deadline: "48 Hours", levelofwork: "Masters", price: 12.50 },
          { deadline: "24 Hours", levelofwork: "Masters", price: 13.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline && item.levelofwork === levelofwork);
        break;
      case "Financial Analysis":
        this.prices = [
          { deadline: "7 Days", levelofwork: "Graduate", price: 19.95 },
          { deadline: "4 Days", levelofwork: "Graduate", price: 21.95 },
          { deadline: "3 Days", levelofwork: "Graduate", price: 23.95 },
          { deadline: "48 Hours", levelofwork: "Graduate", price: 25.95 },
          { deadline: "24 Hours", levelofwork: "Graduate", price: 27.95 },
          { deadline: "7 Days", levelofwork: "Masters", price: 21.95 },
          { deadline: "4 Days", levelofwork: "Masters", price: 23.95 },
          { deadline: "3 Days", levelofwork: "Masters", price: 25.95 },
          { deadline: "48 Hours", levelofwork: "Masters", price: 27.95 },
          { deadline: "24 Hours", levelofwork: "Masters", price: 29.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline && item.levelofwork === levelofwork);
        break;
      case "Dissertation/Thesis":
        this.prices = [
          { deadline: "45 Days", levelofwork: "Graduate", price: 11.95 },
          { deadline: "1 Month", levelofwork: "Graduate", price: 12.95 },
          { deadline: "15 Days", levelofwork: "Graduate", price: 13.95 },
          { deadline: "7 Days", levelofwork: "Graduate", price: 15.95 },
          { deadline: "4 Days", levelofwork: "Graduate", price: 17.95 },
          { deadline: "45 Days", levelofwork: "Masters", price: 14.95 },
          { deadline: "1 Month", levelofwork: "Masters", price: 15.95 },
          { deadline: "15 Days", levelofwork: "Masters", price: 16.95 },
          { deadline: "7 Days", levelofwork: "Masters", price: 18.95 },
          { deadline: "4 Days", levelofwork: "Masters", price: 20.95 },
          { deadline: "45 Days", levelofwork: "Doctorate", price: 20.95 },
          { deadline: "1 Month", levelofwork: "Doctorate", price: 20.95 },
          { deadline: "15 Days", levelofwork: "Doctorate", price: 22.95 },
          { deadline: "7 Days", levelofwork: "Doctorate", price: 26.95 },
          { deadline: "4 Days", levelofwork: "Doctorate", price: 30.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline && item.levelofwork === levelofwork);
      case "PowerPoint Presentation with Speech":
        this.prices = [
          { deadline: "14 Days", price: 12.95 },
          { deadline: "6-7 Days", price: 14.95 },
          { deadline: "5 Days", price: 16.95 },
          { deadline: "4 Days", price: 18.95 },
          { deadline: "3 Days", price: 20.95 },
          { deadline: "48 Hours", price: 22.95 },
          { deadline: "24 Hours", price: 24.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline);
      case "Formatting":
        this.prices = [
          { deadline: "14 Days", levelofwork: "Graduate", price: 15.95 },
          { deadline: "6-7 Days", levelofwork: "Graduate", price: 17.95 },
          { deadline: "5 Days", levelofwork: "Graduate", price: 19.95 },
          { deadline: "4 Days", levelofwork: "Graduate", price: 21.95 },
          { deadline: "3 Days", levelofwork: "Graduate", price: 23.95 },
          { deadline: "48 Hours", levelofwork: "Graduate", price: 25.95 },
          { deadline: "24 Hours", levelofwork: "Graduate", price: 27.95 },
          { deadline: "14 Days", levelofwork: "Masters", price: 17.95 },
          { deadline: "6-7 Days", levelofwork: "Masters", price: 19.95 },
          { deadline: "5 Days", levelofwork: "Masters", price: 21.95 },
          { deadline: "4 Days", levelofwork: "Masters", price: 23.95 },
          { deadline: "3 Days", levelofwork: "Masters", price: 25.95 },
          { deadline: "48 Hours", levelofwork: "Masters", price: 27.95 },
          { deadline: "24 Hours", levelofwork: "Masters", price: 29.95 },
          { deadline: "14 Days", levelofwork: "Doctorate", price: 23.95 },
          { deadline: "6-7 Days", levelofwork: "Doctorate", price: 27.95 },
          { deadline: "5 Days", levelofwork: "Doctorate", price: 29.95 },
          { deadline: "4 Days", levelofwork: "Doctorate", price: 31.95 },
          { deadline: "3 Days", levelofwork: "Doctorate", price: 33.95 },
          { deadline: "48 Hours", levelofwork: "Doctorate", price: 35.95 },
          { deadline: "24 Hours", levelofwork: "Doctorate", price: 37.95 }
        ];
        return this.prices.filter(item => item.deadline === deadline && item.levelofwork === levelofwork);
        break;
    }
  }
}
