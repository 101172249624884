import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Order } from './order';
import { Discount } from './discount';
import { environment } from '../../environments/environment';
//import { Observable } from 'rxjs';

@Injectable()
export class AcademicOrderService {
  neworder: Order;
  body;
  public discount: Discount;
  baseUrl: string;
  secretKey: string;
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.secretKey = environment.secretKey;
   // this.baseUrl = 'https://webapi.assignmentprovider-aus.com/';
   //this.baseUrl = 'http://localhost:51269/';
  }  

  SaveOrder(ord: Order) {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('ApiKey', this.secretKey);
    this.body = {
      model:ord
    }
   // return this.http.post<Order>('http://localhost:51269/api/Order/save', ord, { headers });
    return this.http.post<Order>(this.baseUrl + 'api/Order/save', ord, { headers });
    
  }


  SaveOrderData(ord: Order): Observable<Order> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8;').set('ApiKey', 'MySecretKey').set('Accept', '*/*');
    return this.http.post(this.baseUrl + 'api/Order/save', ord, { headers }).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      );
    
  }


  GetDiscount(code: string): Observable<boolean> {
    
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8;').set('ApiKey', 'MySecretKey').set('Accept', '*/*');
    return this.http
      .get(this.baseUrl + 'api/Order/get-discount?discountcode=' + code, { headers })
      .pipe(
        map((data: any) => {
          this.discount = data;
          return true;
        })
      );
  }

  GetOrder(email: string, orderid: string): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8;').set('ApiKey', 'MySecretKey').set('Accept', '*/*');
    return this.http
      .get(this.baseUrl + 'api/Order/get-order?orderid=' + orderid + "&email=" + email, { headers })
      .pipe(
        map((data: Order) => {
          this.neworder = data;
          return true;
        })
      );
  }

  //GetDiscount(code: string) {
  //  const headers = new HttpHeaders().set('content-type', 'application/json').set('ApiKey', 'MySecretKey');
  //  return this.http.post<Discount>('https://localhost:44380/api/Order/get-discount?discountcode=' + code, { headers });

  //}

}


