import { Component, OnInit, Input } from '@angular/core';
import { OrderFormComponent } from "../order-form/order-form.component";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {

  siteCurrency: string;

  constructor(private orderform: OrderFormComponent) { }
  
  ngOnInit() {
    this.siteCurrency = environment.currency;
  }

}
