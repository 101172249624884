import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AcademicOrderService } from '../services/academic-order.service';
import { Order } from '../services/order';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.css']
})
export class OrderPreviewComponent implements OnInit {
  order: Order;
  orderid: string;
  email: string;
  siteCurrency: string;


  constructor(private route: ActivatedRoute, public orderservice: AcademicOrderService, private ngxLoader: NgxUiLoaderService, private router: Router,) { }

  ngOnInit() {
    this.siteCurrency = environment.currency;
    this.route.params
      .subscribe(
        (params: Params) => {
          this.ngxLoader.start();
          this.orderid = params['orderid'];
          this.email = params['email'];

          console.log('email: ' + this.email + ', orderid: ' + this.orderid);

          this.orderservice.GetOrder(this.email, this.orderid).subscribe(success => {
            if (success) {
              if (this.orderservice.neworder !== undefined) {
                this.order = this.orderservice.neworder;
                console.log(this.order);
                this.ngxLoader.stop();
              }
              else {
                this.ngxLoader.stop();
              }
            }
          });
          
        }
      );
  }

  checkOut() {
    window.location.href = 'https://www.plimus.com/jsp/buynow.jsp?contractId=3223702&currency=AUD&overridePrice=' + this.order.totalPrice + '&bCur=AUD';
  }

  editOrder() {
    this.router.navigate(['/order/' + this.orderid + '/' + this.email]);
  }

}

